import React, { useEffect, useState } from 'react';

import logo from './logo.svg';
import './App.css';

function App() {
  
  const [state, setState] = useState(null);

  const callBackendAPI = async() => {

	const response = await fetch('/api/test');
    const body = await response.json();

	if (response.status === 304) {
		return 'STATUS 304'
    }
	else if (response.status !== 200) {
      	
		throw Error(body.message)
    }
    return 'OK';		// body
  };
  
  // получение GET маршрута с сервера Express, который соответствует GET из server.js 
  useEffect(() => {
    callBackendAPI()
    .then(res => setState(res))
    .catch(err => console.log(err));
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
		<div>
			Тестирование проекта - Версия 0.00.08
	    </div>
		<br/>
		<div>
			BACKEND CHECK - {state || "ERROR"}
	    </div>

      </header>
    </div>
  );
}

export default App;